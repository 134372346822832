import React from "react"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import CourseFooterNext from "../../../../../components/course-footer-next"
import FeebackHeaderProgress from "../../../../../components/feedback-header-progress"
import { getCourseNavigation } from "../../../../../store/courses"
import { useCourseStore } from "../../../../../store/courses"
import NotationGroup from "../../../../../components/notation-group"
import PoemFruehlingNotationSolution from "../../../../../content/kurse/jahreszeiten/01-jahreszeitengedicht/poem-fruehling-notation-solution"
import Score from "../../../../../components/score"
import Columns from "../../../../../components/columns"
import Column from "../../../../../components/column"

const Page = () => {
  const { answerStatusForDict, getStatsForTaskWithDict } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "jahreszeiten" })
  const task = {
    courseId: "jahreszeiten",
    chapterId: "01-jahreszeitengedicht",
    taskId: "nomen",
  }
  const comparator = ({ answer, solution, status }) => {
    if (answer?.active === true && solution === true) {
      return status.VALID_CHECKED
    }
    if (answer?.active === true && solution !== true) {
      return status.INVALID
    }
    if (solution && !answer?.active) {
      return status.MISSED
    }
    return status.VALID_UNCHECKED
  }
  const answers = answerStatusForDict({
    ...task,
    comparator,
  })
  const statistics = getStatsForTaskWithDict({
    ...task,
    comparator,
  })

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext
          title="Alles klar soweit?"
          label="Weiter"
          to="/kurse/jahreszeiten/01-jahreszeitengedicht/nomen/zusammenfassung"
        />
      }
    >
      <Seo title="Hölderlin und die Jahreszeiten" />
      <Stack>
        <FeebackHeaderProgress
          variant="solution"
          correct={statistics.correct}
          total={statistics.correct + statistics.missed}
        />
        <Columns collapse={[true, true, false]} space={6}>
          <Column width={["100%", "100%", "7/12"]}>
            <NotationGroup initialState={answers}>
              <PoemFruehlingNotationSolution />
            </NotationGroup>
          </Column>
          <Column width={["100%", "100%", "5/12"]}>
            <Score statistics={statistics} />
          </Column>
        </Columns>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
